import React from "react";
import PageWrapper from "../components/PageWrapper";
import Testimonial from "../sections/index/indexreviews.js";
import Nongallery from "../sections/filler/fillerG.js";
import Contentfiller from "../sections/filler/fillercontent.js";
import FillerFaq from "../sections/filler/fillerfaq.js";
import Head from "../components/head"
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/fillerp.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";

const Filler = () => {
  return (
    <>
      <Head title="Filler Packages Treatments UK" image="/images/dermamina-home.jpg"  description="experts in Filler Packages treatments London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
<PageWrapper headerDark>
<HeroComponent
    itext='<span style="color: #1a1a1a;">Filler </span> <span style="color: #00aec7;">Packages</span>'
    secondText="Instant results for more than one treatment"
    videoAlt="Filler Packages"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Filler Packages"
  resultstext="6 - 18 months"
  resultsalt="Filler Packages results"
  costdata="From £860"
  costalt="Filler Packages Cost"
  timedata="4-+ Minutes"
  timealt="Filler Packages Duration Time"
  workdata="Immediately"
  workalt="Filler Packages downtime work"
  paindata="None / Mild"
  painalt="Filler Packages Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Nongallery />
<Award />
<Testimonial />
<Contentfiller />

<FillerFaq />        
<Clinic /> 
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default Filler;
